require('normalize.css/normalize.css');
require('./scss/index.scss');
require('isotope-layout');
import 'jquery';
import 'bootstrap';
import '@fortawesome/fontawesome-free/js/all.js';
import 'slick-carousel';

document.addEventListener("DOMContentLoaded", () => {


});

var Isotope = require('isotope-layout');


$(window).on('load', function () {
    // ISOTOPE GRID
    if ($('.grid').length) {
        var iso = new Isotope('.grid', {
            itemSelector: '.grid-item',
            percentPosition: true,
            masonry: {
                columnWidth: '.grid-sizer'
            }
        });
    }
});

$(document).ready(() => {

    // TOGGLE IMPRESSUM
    $('.impressum').on('shown.bs.collapse', function (e) {
        e.preventDefault();
        $('html, body').animate({
            scrollTop: $('#impressum').offset().top
        }, 1000);
    });

    // TOGGLE DATENSCHUTZ
    $('.dataprotection').on('shown.bs.collapse', function (e) {
        e.preventDefault();
        $('html, body').animate({
            scrollTop: $('#dataprotection').offset().top
        }, 1000);
    });



    // SLICK SLIDER
    $('.slider').on('init', function() {
        $(this).find('.slick-current .overlay').delay(1000).fadeIn(500);
        $(this).find('.slick-current img').delay(1000).animate({ 'opacity': '0.2'}, 1000);

    }).on('beforeChange', function () {
        $('.slider').find('img').animate({ 'opacity': '1'});


    }).on('afterChange', function(){
        $(this).find('.slick-slide:not(.slick-current) .overlay').hide(0);
        $(this).find('.slick-slide:not(.slick-current) img').animate({ 'opacity': '1'}, 0);
        $(this).find('.slick-current .overlay').fadeIn(500);
        $(this).find('.slick-current img').animate({ 'opacity': '0.2'}, 1000);


    }).slick({
       slidesToShow: 1,
       arrows: true,
        autoplay: true,
        autoplaySpeed: 5000,
        infinite: true
    });
});

